var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "monitoring-items-upload mt-4"
  }, [_c('b-card', [_c('b-row'), _c('b-row', {
    staticClass: "pb-2"
  }, [_c('div', {
    staticClass: "col-auto"
  }, [_c('b-form-select', {
    staticClass: "mb-2",
    attrs: {
      "id": "currency",
      "options": _vm.statusOptions,
      "required": ""
    },
    on: {
      "change": _vm.fetchItemProcess
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v;
      },
      expression: "status"
    }
  })], 1), _c('b-col', {
    attrs: {
      "cols": "6"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "search",
      "placeholder": "Type to Search"
    },
    on: {
      "input": _vm.onSearchItem
    },
    model: {
      value: _vm.filter,
      callback: function ($$v) {
        _vm.filter = $$v;
      },
      expression: "filter"
    }
  })], 1), _c('b-col', {
    staticClass: "justify-content-end pt-1 d-flex align-items-end"
  }, [_c('span', {
    staticClass: "pr-2",
    attrs: {
      "id": "button-reload"
    }
  }, [_c('button', {
    staticClass: "btn btn-sm btn-dark",
    on: {
      "click": _vm.reloadBtn
    }
  }, [_c('i', {
    staticClass: "fa fa-refresh"
  })])]), _c('span', [_vm._v(" Count : "), _c('strong', {
    staticClass: "col-sm-auto"
  }, [_vm._v(_vm._s(_vm.totalRows))])])])], 1), _vm.items ? _c('b-table', {
    attrs: {
      "id": "table-monitoring-item-uploads",
      "sticky-header": "600px",
      "items": _vm.items,
      "fields": _vm.fields,
      "busy": _vm.isLoading,
      "responsive": "",
      "striped": "",
      "hover": ""
    },
    scopedSlots: _vm._u([{
      key: "cell(No)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s((_vm.currentPage - 1) * _vm.perPage + (row.index + 1)) + " ")];
      }
    }, {
      key: "cell(name)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.items ? row.item.items.name : '') + " "), _c('strong', [_vm._v(" #" + _vm._s(row.item.item_id) + " ")])];
      }
    }, {
      key: "cell(edition_code)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.items ? row.item.items.edition_code : '') + " ")];
      }
    }, {
      key: "cell(release_date)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.datetime24H(row.item.items.release_date)) + " ")];
      }
    }, {
      key: "cell(start_process)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.start_process_time ? _vm.datetime24H(row.item.start_process_time) : 'Not Started') + " ")];
      }
    }, {
      key: "cell(end_process)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.end_process_time ? _vm.datetime24H(row.item.start_process_time) : 'Not Started') + " ")];
      }
    }, {
      key: "cell(created)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm.datetime24H(row.item.created)) + " ")];
      }
    }, {
      key: "cell(pic)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(row.item.pic_name) + " ")];
      }
    }, {
      key: "cell(status)",
      fn: function (row) {
        return [_c('div', [row.item.status == 10 ? _c('span', {
          staticClass: "badge badge-success"
        }, [_vm._v(" Success ")]) : row.item.status == 2 ? _c('span', {
          staticClass: "badge badge-warning"
        }, [_vm._v(" Processing ")]) : row.item.status == 11 ? _c('span', {
          staticClass: "badge badge-danger"
        }, [_vm._v(" Error ")]) : _c('span', {
          staticClass: "badge badge-info"
        }, [_vm._v(" Waiting for processing ")])])];
      }
    }, {
      key: "cell(file_sizes)",
      fn: function (row) {
        return [_vm._v(" " + _vm._s(_vm._f("fileSizeFormat")(row.item.file_size, 1)) + " ")];
      }
    }, {
      key: "cell(action)",
      fn: function (row) {
        return [_c('div', {
          staticClass: "d-flex"
        }, [_vm.isShowBtnQueue(row.item.status) ? _c('b-button', {
          staticClass: "mr-1",
          attrs: {
            "size": "sm",
            "variant": "danger",
            "title": "delete this queue"
          },
          on: {
            "click": function ($event) {
              return _vm.onDelete(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-trash"
        })]) : _vm._e(), _vm.isShowBtnQueue(row.item.status) ? _c('b-button', {
          attrs: {
            "size": "sm",
            "variant": "warning",
            "title": "re-queue"
          },
          on: {
            "click": function ($event) {
              return _vm.onReQueue(row.item);
            }
          }
        }, [_c('i', {
          staticClass: "fa fa-retweet"
        })]) : _vm._e()], 1)];
      }
    }, {
      key: "table-busy",
      fn: function () {
        return [_c('div', {
          staticClass: "text-center text-dark my-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle",
          attrs: {
            "variant": "dark",
            "type": "grow",
            "label": "Loading .."
          }
        }), _vm._v(" Loading .. ")], 1)];
      },
      proxy: true
    }], null, false, 688700837)
  }) : _vm._e(), _c('b-row', {
    staticClass: "justify-content-start ml-1"
  }, [_c('b-col', {
    attrs: {
      "lg": "3"
    }
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.totalRows,
      "per-page": _vm.perPage,
      "aria-controls": "my-table"
    },
    model: {
      value: _vm.currentPage,
      callback: function ($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }